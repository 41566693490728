

@media (min-width: 768px) and (max-width: 1079px) {
  :root {
    --cache-version: 1;
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .hide-on-tablet {
    display: none;
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  p {
    font: var(--font-desktop-body1);
  }

  strong {
    font: var(--font-desktop-body1-bold);
  }

  table thead {
    font: var(--font-desktop-body2-bold);
  }

  table tbody {
    font: var(--font-desktop-body2);
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .flash-message__list {
    max-width: calc(100% - var(--spacing-64));
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .footer__themes-title {
    font: var(--font-mobile-h4);
  }

  .footer__themes-logo img {
    display: none;
  }

  .footer__themes--bottom .footer__theme-link {
    font: var(--font-mobile-h5);
  }

  .footer__categories {
    order: 2;
  }

  .footer__categories--wrapper {
    gap: var(--spacing-48);
    flex-wrap: wrap;
  }

  .footer__categories--wrapper .footer__category {
    flex-basis: calc(33.3333% - var(--spacing-48));
  }

  .footer__categories--wrapper .footer__category-main {
    font: var(--font-mobile-h4);
  }

  .footer__categories--wrapper .footer__subcategory {
    font: var(--font-mobile-meta-regular);
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .footer__contacts {
    order: 1;
  }

  .footer__contacts-links {
    font: var(--font-mobile-body2-bold);
    justify-content: center;
  }

  .footer__contacts-link {
    padding: var(--spacing-8) 0;
  }

  .footer__applications-wrapper {
    gap: var(--spacing-40);
    flex-direction: column;
    align-items: center;
  }

  .footer__applications {
    gap: var(--spacing-24);
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .footer__copyright {
    font: var(--font-mobile-meta-regular);
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .container--main {
    grid-template-rows: auto auto auto;
  }

  .container--main .main--first-content, .container--main .main--second-content {
    padding: var(--spacing-24) var(--spacing-44);
    grid-template-columns: [lend] 1fr[ldivider] 1fr[rdivider] 1fr[rend];
    max-width: 680px;
    margin: 0 auto;
  }

  .container--main .main--first-content {
    padding-bottom: 0;
  }

  .container--main .main--second-content {
    padding-top: 0;
  }

  .container--main > footer > .footer-wrapper {
    padding: var(--spacing-40) var(--spacing-44);
  }

  .grid-233 {
    grid-template-columns: [lend] 1fr[ldivider] 1fr[rdivider] 1fr[rend];
  }

  .grid-113, .grid-233 {
    gap: var(--spacing-32);
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .header {
    padding: var(--spacing-8) var(--spacing-44);
    row-gap: var(--spacing-12);
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .header-logo {
    margin-right: auto;
    transition: height .2s;
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .header-desktop-navigation:not(:has(.overflow-menu.active)) {
    overflow: hidden;
  }

  .header-menu {
    max-width: 99%;
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .mobile-navigation {
    display: none;
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .megamenu-wrapper {
    top: var(--spacing-64);
    padding: var(--spacing-32) var(--spacing-16);
    gap: var(--spacing-32);
    grid-template: [r1-start] "search search search search" max-content [r1-end]
                   [r2-start] "stickers stickers stickers stickers" max-content [r2-end]
                   [r3-start] "categories categories categories print" max-content [r3-end]
                   [r4-start] "categories categories categories print" max-content [r4-end]
                   [r5-start] "categories categories categories print" max-content [r5-end]
                   [r6-start] "divider divider divider divider" max-content [r6-end]
                   [r7-start] "applications applications applications applications" max-content [r7-end]
                   [r8-start] "social social social social" max-content [r8-end]
                   [r9-start] "contact contact contact contact" max-content [r9-end]
                   / 1fr 1fr 1fr 1fr;
  }

  .megamenu-wrapper.opened {
    max-width: 100%;
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .megamenu-close {
    display: none;
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .megamenu-stickers__item, .megamenu-stickers__item > a, .megamenu-stickers__item > span {
    font: var(--font-desktop-meta-regular);
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .megamenu-categories {
    gap: var(--spacing-24);
    flex-wrap: wrap;
  }

  .megamenu-categories__item {
    row-gap: var(--spacing-12);
    flex-basis: calc(33% - 20px);
  }

  .megamenu-categories__item-title {
    font: var(--font-desktop-h4);
  }

  .megamenu-categories .megamenu-subcategories {
    gap: var(--spacing-8);
    font: var(--font-desktop-body2);
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .megamenu-print {
    gap: var(--spacing-12);
  }

  .megamenu-print__title {
    font: var(--font-desktop-h4);
  }

  .megamenu-print__subtitle {
    font: var(--font-desktop-body2);
  }

  .megamenu-print__covers {
    gap: var(--spacing-16);
    flex-direction: column;
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .megamenu-logo {
    display: none;
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .megamenu-applications {
    justify-content: center;
  }

  .megamenu-applications__title {
    font: var(--font-desktop-h4);
  }

  .megamenu-applications__subtitle {
    font: var(--font-desktop-body2);
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .megamenu-social {
    justify-content: center;
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .megamenu-contact {
    align-items: center;
  }

  .megamenu-contact__title {
    font: var(--font-desktop-h4);
  }

  .megamenu-contact__email {
    font: var(--font-desktop-body2);
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .megamenu-newsletter {
    align-items: center;
  }

  .megamenu-newsletter__title {
    font: var(--font-desktop-h4);
  }

  .megamenu-newsletter__email {
    font: var(--font-desktop-body2);
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .section--columns-233 {
    grid-column: 1 / 4;
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .section--columns-232 {
    grid-column: 1 / 4;
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .typography--h1, .typography--heading-3xl {
    font: var(--typography-desktop-heading-3xl-font-size) / var(--typography-desktop-heading-3xl-line-height) var(--heading-3xl-font-family);
  }

  .typography--heading-2xl {
    font: var(--typography-desktop-heading-2xl-font-size) / var(--typography-desktop-heading-2xl-line-height) var(--heading-2xl-font-family);
  }

  .typography--h2, .typography--heading-xl {
    font: var(--typography-desktop-heading-xl-font-size) / var(--typography-desktop-heading-xl-line-height) var(--heading-xl-font-family);
  }

  .typography--h3, .typography--heading-lg {
    font: var(--typography-desktop-heading-lg-font-size) / var(--typography-desktop-heading-lg-line-height) var(--heading-lg-font-family);
  }

  .typography--h4, .typography--heading-md {
    font: var(--typography-desktop-heading-md-font-size) / var(--typography-desktop-heading-md-line-height) var(--heading-md-font-family);
  }

  .typography--h5, .typography--heading-sm {
    font: var(--typography-desktop-heading-sm-font-size) / var(--typography-desktop-heading-sm-line-height) var(--heading-sm-font-family);
  }

  .typography--heading-xs {
    font: var(--typography-desktop-heading-xs-font-size) / var(--typography-desktop-heading-xs-line-height) var(--heading-xs-font-family);
  }

  .typography--heading-2xs {
    font: var(--typography-desktop-heading-2xs-font-size) / var(--typography-desktop-heading-2xs-line-height) var(--heading-2xs-font-family);
  }

  .typography--inter-title {
    font: var(--typography-desktop-inter-title-font-size) / var(--typography-desktop-inter-title-line-height) var(--inter-title-font-family);
  }

  .typography--section-title {
    font: var(--typography-desktop-section-title-font-size) / var(--typography-desktop-section-title-line-height) var(--section-title-font-family);
  }

  .typography--subtitle1, .typography--subtitle-lg {
    font: var(--typography-desktop-subtitle-lg-font-size) / var(--typography-desktop-subtitle-lg-line-height) var(--subtitle-lg-font-family);
  }

  .typography--subtitle1-bold, .typography--subtitle-lg-bold {
    font: 700 var(--typography-desktop-subtitle-lg-font-size) / var(--typography-desktop-subtitle-lg-line-height) var(--subtitle-lg-bold-font-family);
  }

  .typography--subtitle2, .typography--subtitle-md {
    font: var(--typography-desktop-subtitle-md-font-size) / var(--typography-desktop-subtitle-md-line-height) var(--subtitle-md-font-family);
  }

  .typography--subtitle2-bold, .typography--subtitle-md-bold {
    font: var(--typography-desktop-subtitle-md-bold-font-size) / var(--typography-desktop-subtitle-md-line-height) var(--subtitle-md-bold-font-family);
  }

  .typography--body1, .typography--body-lg {
    font: var(--typography-desktop-body-lg-font-size) / var(--typography-desktop-body-lg-line-height) var(--body-lg-font-family);
  }

  .typography--body1-bold, .typography--body-lg-bold {
    font: 700 var(--typography-desktop-body-lg-font-size) / var(--typography-desktop-body-lg-line-height) var(--body-lg-bold-font-family);
  }

  .typography--body2, .typography--body-md {
    font: var(--typography-desktop-body-md-font-size) / var(--typography-desktop-body-md-line-height) var(--body-md-font-family);
  }

  .typography--body2-bold, .typography--body-md-bold {
    font: 700 var(--typography-desktop-body-md-font-size) / var(--typography-desktop-body-md-line-height) var(--body-md-bold-font-family);
  }

  .typography--meta-regular, .typography--body-sm {
    font: var(--typography-desktop-body-sm-font-size) / var(--typography-desktop-body-sm-line-height) var(--body-sm-font-family);
  }

  .typography--meta-bold, .typography--body-sm-bold {
    font: 700 var(--typography-desktop-body-sm-font-size) / var(--typography-desktop-body-sm-line-height) var(--body-sm-bold-font-family);
  }

  .typography--body-xs {
    font: var(--typography-desktop-body-xs-font-size) / var(--typography-desktop-body-xs-line-height) var(--body-xs-font-family);
  }

  .typography--body-xs-bold {
    font: 700 var(--typography-desktop-body-xs-font-size) / var(--typography-desktop-body-xs-line-height) var(--body-xs-bold-font-family);
  }

  .typography--button-large, .typography--button-lg {
    font: var(--typography-desktop-button-lg-font-size) / var(--typography-desktop-button-lg-line-height) var(--button-lg-font-family);
  }

  .typography--button-medium, .typography--button-md {
    font: var(--typography-desktop-button-md-font-size) / var(--typography-desktop-button-md-line-height) var(--button-md-font-family);
  }

  .typography--button-small, .typography--button-sm {
    font: var(--typography-desktop-button-sm-font-size) / var(--typography-desktop-button-sm-line-height) var(--button-sm-font-family);
  }

  .typography--menu-item {
    font: var(--typography-desktop-menu-item-font-size) / var(--typography-desktop-menu-item-line-height) var(--menu-item-font-family);
  }

  .typography--label {
    font: var(--typography-desktop-label-font-size) / var(--typography-desktop-label-line-height) var(--label-font-family);
  }

  .typography--helper-text {
    font: var(--typography-desktop-helper-text-font-size) / var(--typography-desktop-helper-text-line-height) var(--helper-text-font-family);
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .author-card--author-detail .author-card__role {
    grid-area: 2 / 2;
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .author-detail-wrapper {
    grid-column: lend / rend;
  }

  .author-card--author-detail {
    padding-bottom: var(--spacing-32);
  }

  .author-card--author-detail .social-media {
    grid-area: 1 / 3;
    justify-self: end;
  }

  .author-card--author-detail .author-card__mail-link {
    justify-self: end;
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .breadcrumb {
    grid-column: lend / rend;
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .load-more-disabled__title {
    font: var(--font-desktop-h4);
  }

  .load-more-disabled__subtitle {
    font: var(--font-desktop-body2);
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .partner-promo__partner {
    font: var(--font-mobile-meta-bold);
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .print__cover {
    gap: var(--spacing-12);
  }

  .print__cover > a > img {
    width: 128px;
    height: auto;
  }

  .print__cover .button {
    width: 128px;
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .promo-subscription {
    align-self: flex-start;
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .premium-card {
    height: 499px;
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .topic__custom-article p {
    font: var(--font-desktop-body1);
  }

  .topic__custom-article strong {
    font: var(--font-desktop-body1-bold);
  }

  .topic__custom-article table thead {
    font: var(--font-desktop-body2-bold);
  }

  .topic__custom-article table tbody {
    font: var(--font-desktop-body2);
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .tag, .tag a, .tag span {
    font: var(--font-desktop-meta-regular);
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .ad-position--tablet {
    display: block;
  }

  .ad-category--left-column {
    grid-column: 1 / -1;
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .wrapper--main > .grid-233 > aside {
    display: none;
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .wiki-search {
    grid-column: lend / rend;
  }

  .wiki-search__title {
    font: var(--font-mobile-h2);
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .category-card-list {
    grid-template-columns: 1fr 1fr;
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .category-info__description p {
    font: var(--font-desktop-body1);
  }

  .category-info__description strong {
    font: var(--font-desktop-body1-bold);
  }

  .category-info__description table thead {
    font: var(--font-desktop-body2-bold);
  }

  .category-info__description table tbody {
    font: var(--font-desktop-body2);
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .category-info {
    flex-direction: column;
    width: 100%;
    display: flex;
  }

  .category-info__menu {
    margin: var(--spacing-32) 0 var(--spacing-24);
    font: var(--font-desktop-body2);
    color: var(--text-primary);
    width: 100%;
    height: 44px;
    position: relative;
    overflow: visible;
  }

  .category-info__menu > ul {
    z-index: 2;
    padding: var(--spacing-12) var(--spacing-48) var(--spacing-12) var(--spacing-16);
    border: 1px solid var(--other-outline-border);
    background: var(--background-paper-contrast);
    border-radius: 4px;
    width: 100%;
    min-height: 44px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  .category-info__menu > ul:before {
    content: "Menu";
    top: var(--spacing-12);
    left: var(--spacing-16);
    position: absolute;
  }

  .category-info__menu > ul:after {
    content: "";
    top: 18px;
    right: calc(var(--spacing-48) / 2 - 6px);
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMiAxLjQxIDEwLjU5IDAgNiA0LjU4IDEuNDEgMCAwIDEuNDFsNiA2IDYtNloiIGZpbGw9IiMwMDAiLz48L3N2Zz4=") no-repeat;
    width: 12px;
    height: 8px;
    transition: transform .2s ease-in-out;
    display: block;
    position: absolute;
    transform: rotateX(0);
  }

  .category-info__menu > ul li {
    display: none;
  }

  .category-info__menu--opened > ul {
    border: 2px solid var(--primary-main);
    flex-direction: column;
    width: 100%;
    display: flex;
  }

  .category-info__menu--opened > ul:after {
    transition: transform .2s ease-in-out;
    transform: rotateX(180deg);
  }

  .category-info__menu--opened > ul li {
    background: var(--background-paper-contrast);
    flex: 1;
    display: block;
    position: relative;
  }

  .category-info__menu--opened > ul li:hover {
    background: var(--action-hover);
  }

  .category-info__menu--opened > ul li:hover > a {
    color: var(--primary-main);
  }

  .category-info__menu--opened > ul > li:first-child {
    margin-top: var(--spacing-32);
  }

  .category-info__menu-item--active {
    border-left: 2px solid var(--primary-main);
  }

  .category-info__menu-link {
    padding: var(--spacing-12) 0 var(--spacing-12) var(--spacing-16);
    color: var(--text-primary);
    width: 100%;
    text-decoration: none;
    display: block;
  }

  .category-info span.category-info__menu-link {
    display: none;
  }

  .category-info h1 {
    font: var(--font-mobile-h2);
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .profile-user {
    min-width: 680px;
  }

  .profile-user strong {
    font: var(--font-desktop-body1-bold);
  }

  .profile-user__cancel-reccuring strong .recurring-info-popup, .profile-user__cancel-newsletter strong .recurring-info-popup {
    font: var(--font-desktop-meta-regular);
  }

  .profile-user__voucher-title {
    font: var(--font-desktop-body1-bold);
  }

  .form-review__field label, .form-review__field textarea {
    font: var(--font-desktop-meta-regular);
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .thanks {
    grid-column: 1 / 4;
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .list-articles-wrapper--vertical, .list-articles-wrapper--horizontal {
    grid-column: 1 / 4;
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .topic-banner {
    justify-content: flex-start;
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .topic-banner__title {
    max-width: 500px;
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .list-article-author {
    grid-column: 1 / 4;
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .list-article-author-total-2 {
    cursor: grab;
  }

  .list-article-author-total-2:active {
    cursor: grabbing;
  }
}


@media (min-width: 768px) and (max-width: 1079px) {
  .megamenu-wrapper {
    grid-template: [r1-start] "search search search search" max-content [r1-end]
                   [r2-start] "stickers stickers stickers stickers" max-content [r2-end]
                   [r3-start] "categories categories categories print" max-content [r3-end]
                   [r4-start] "categories categories categories print" max-content [r4-end]
                   [r5-start] "categories categories categories print" max-content [r5-end]
                   [r6-start] "divider divider divider divider" max-content [r6-end]
                   [r7-start] "applications applications applications applications" max-content [r7-end]
                   [r8-start] "social social social social" max-content [r8-end]
                   [r9-start] "newsletter newsletter newsletter newsletter" max-content [r9-end]
                   / 1fr 1fr 1fr 1fr;
  }
}